import * as React from "react";
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence, IPersonaStyles } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { ICustomer } from "../../interfaces/models";
import Config from "../../Config";

export interface CustomerListProps {
  Customers: ICustomer[];
  toggleDisplayCustomer: (customer: ICustomer) => any;
}

export interface CustomerListState {}

export class CustomerList extends React.Component<CustomerListProps, CustomerListState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  click = (customer: ICustomer) => {
    this.props.toggleDisplayCustomer(customer);
  };

  personaStyle(): Partial<IPersonaStyles> {
    return {
      root: [
        {
          cursor: "pointer",
        },
      ],
    };
  }

  render() {
    const createPersona: any[] = this.props.Customers.map((customer: ICustomer) => {
      let anrede = Config.anreden[customer.Anrede];
      let displayName = customer.Person.Vorname + " " + customer.Person.Nachname;
      if (anrede === Config.anreden["7"]) {
        displayName = customer.Person.Firma;
      }

      let customerPersona: IPersonaSharedProps = {
        imageUrl: Config.storageUrl + (anrede === "Herr" ? "male" : anrede === "Frau" ? "female" : "company") + ".png",
        imageInitials: " ",
        text: anrede,
        secondaryText: displayName,
        showSecondaryText: true,
      };

      return (
        <Persona
          key={customer.Id}
          {...customerPersona}
          size={PersonaSize.size32}
          presence={PersonaPresence.none}
          onClick={() => this.click(customer)}
          styles={this.personaStyle}
        />
      );
    });

    return (
      <div>
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          {createPersona}
        </Stack>
      </div>
    );
  }
}

export default CustomerList;
