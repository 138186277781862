/* eslint-disable prettier/prettier */
import * as React from "react";
import { ICustomer } from "../../interfaces/models";
import { Label, SearchBox, Stack, IconButton, ContextualMenu, DefaultButton, Separator } from "@fluentui/react";
import DataService from "./DataService";
import CustomerList from "./CustomerList";
import Progress from "./Progress";
import DisplayCustomer from "./DisplayCustomer";
import Config from "../../Config";
import Login from "./Login";
import "./Components.css";

export interface ContainerProps {}

export interface ContainerState {
  Customers: ICustomer[];
  focusedCustomer: ICustomer;
  dataLoaded: boolean | null;
  searchLoaded: boolean;
  canDisplayCustomer: boolean;
  searchTooShortError: boolean;
  toggleDisplayCustomer: (customer: ICustomer) => any;
  deselectCustomer: () => any;
  isMenuOpen: boolean;
  searchText: string;
  searchFromMenu: boolean;
}

export class Container extends React.Component<ContainerProps, ContainerState> {
  constructor(props) {
    super(props);
    this.state = {
      Customers: [],
      focusedCustomer: null,
      dataLoaded: null,
      searchLoaded: true,
      canDisplayCustomer: false,
      searchTooShortError: false,
      toggleDisplayCustomer: this.toggleDisplayCustomer,
      deselectCustomer: this.deselectCustomer,
      isMenuOpen: false,
      searchText: "",
      searchFromMenu: false,
    };

    this.toggleDisplayCustomer = this.toggleDisplayCustomer.bind(this);
    this.deselectCustomer = this.deselectCustomer.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuDismiss = this.onMenuDismiss.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
  }

  handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ searchText: event.target.value });
  }

  handleSearch() {
    const { searchText } = this.state;
    this.searchCustomerByName(searchText);
  }

  handleClearSearch() {
    this.setState({ searchText: "" });
  }

  searchCustomerByName = async (value: string) => {
    await this.setState({ searchLoaded: false });
    if (value && value.length > 2) {
      let customersNew = await DataService.searchCustomersByName(value);
      let fullCustomerData = await Promise.all(
        customersNew.map((customer: ICustomer) => DataService.getAdditionalCustomerData(customer))
      );
      await this.setState({ Customers: fullCustomerData, searchLoaded: true, searchTooShortError: false });
      if (customersNew.length == 1) {
        this.setState({ focusedCustomer: fullCustomerData[0], canDisplayCustomer: true });
      } else if (customersNew.length > 1) {
        this.setState({ focusedCustomer: null, canDisplayCustomer: false });
      } else {
        this.setState({ dataLoaded: false });
      }
    } else {
      await this.setState({
        Customers: [],
        searchLoaded: true,
        searchTooShortError: true,
      });
    }
  };

  async componentDidMount() {
      let customersNew = await DataService.searchCustomers();
      let fullCustomerData = await Promise.all(
        customersNew.map((customer: ICustomer) => DataService.getAdditionalCustomerData(customer))
      );
      this.setState({ Customers: fullCustomerData, dataLoaded: true, searchTooShortError: false });
      if (customersNew.length == 1) {
        this.setState({ focusedCustomer: fullCustomerData[0], canDisplayCustomer: true });
      }
      if (customersNew.length == 0) {
        this.setState({ dataLoaded: false });
      }
  }

  public toggleDisplayCustomer = (customer: ICustomer) => {
    this.setState({ focusedCustomer: customer, canDisplayCustomer: true });
  };

  public deselectCustomer = () => {
    this.setState({ focusedCustomer: null, canDisplayCustomer: false });
  };

  public InitialPage: any = () => {
    if (!(this.state.Customers === undefined || this.state.Customers.length == 0)) {
      if (!this.state.canDisplayCustomer) {
        return (
          <div>
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: "14px" }}>
              <p className="kundenSuche" style={{ fontSize: "20px", margin: "-8px" }}>Kundensuche</p>
              <Separator />
              <SearchBox
                placeholder="Suche nach Vor- und Nachname"
                onSearch={this.handleSearch}
                onChange={this.handleSearchChange}
                onClear={this.handleClearSearch} 
                value={this.state.searchText}
              />
              <DefaultButton onClick={this.handleSearch} className="customButton">
                Suche
              </DefaultButton>
              {!this.state.searchLoaded && <Progress message="Suche...." />}
              {this.state.searchLoaded && !this.state.canDisplayCustomer && <CustomerList {...this.state} />}
            </Stack>
          </div>
        );
      } else {
        return (
          <div>
            <Stack tokens={{ childrenGap: 30 }} style={{ padding: "14px" }}>
              {!this.state.searchLoaded && <Progress message="Suche...." />}
              {this.state.searchLoaded && <DisplayCustomer {...this.state} />}
            </Stack>
          </div>
        );
      }
    }

    if (this.state.searchFromMenu) {
      return (
        <Stack tokens={{ childrenGap: 10 }} style={{ padding: "14px" }}>
          <p className="kundenSuche" style={{ fontSize: "20px", margin: "-8px" }}>Kundensuche</p>
          <Separator />
          <SearchBox
            placeholder="Suche nach Vor- und Nachname"
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            onClear={this.handleClearSearch} 
            value={this.state.searchText}
          />
          <DefaultButton onClick={this.handleSearch} className="customButton">
            Suche
          </DefaultButton>
          {!this.state.searchLoaded && <Progress message="Suche...." />}
          {this.state.searchLoaded && !this.state.canDisplayCustomer && <CustomerList {...this.state} />}
        </Stack>
      );
    }

    if (this.state.dataLoaded === false) {
      return (
        <Stack tokens={{ childrenGap: 10 }} style={{ padding: "14px" }}>
          <p className="kundenSuche" style={{ fontSize: "20px", margin: "-8px" }}>Kundensuche</p>
          <Separator />
          <SearchBox
            placeholder="Suche nach Vor- und Nachname"
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            onClear={this.handleClearSearch} 
            value={this.state.searchText}
          />
          <DefaultButton onClick={this.handleSearch} className="customButton">
            Suche
          </DefaultButton>
          {!this.state.searchLoaded && <Progress message="Suche...." />}{" "}
          {!this.state.searchTooShortError && this.state.searchLoaded && (
            <Label>Es wurde leider kein Kunde mit der E-Mail Adresse im Maklerportal gefunden.</Label>
          )}
          {this.state.searchTooShortError && this.state.searchLoaded && (
            <Label>Bitte gib mindestens 3 Zeichen ein.</Label>
          )}
        </Stack>
      );
    }
    if (this.state.dataLoaded === null) {
      return <Progress message="Kundensuche...." />;
    }
  };

  onMenuClick() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }

  onMenuDismiss() {
    this.setState({
      isMenuOpen: false,
    });
  }

  public handleRefresh = () => {
    window.location.reload();
    this.onMenuDismiss();
  };

  public handleSearchFromMenu = () => {
    this.deselectCustomer();
    this.setState({ searchFromMenu: true });
  };

  goBack() {
    this.deselectCustomer();
  }

  _logout = async () => {
    var left = screen.width / 2 - Config.screenWidth / 2;
    var top = screen.height / 2 - Config.screenHeight / 2;
    window.open(
      window.location.protocol + "//" + window.location.host + `/logmeout`,
      "_blank",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        Config.screenWidth +
        ", height=" +
        Config.screenHeight +
        ", top=" +
        top +
        ", left=" +
        left
    );
    await fetch('/logout', { method: 'POST' });
    location.reload();
    this.onMenuDismiss();
  };

  render() {
    const { isMenuOpen } = this.state;
    return (
      <div>
        <header className="header">
          <IconButton iconProps={{ iconName: "Back" }} className="header-icon" onClick={this.goBack} />
          <IconButton
            id="menuButton"
            iconProps={{ iconName: "MoreVertical" }}
            className="header-icon"
            onClick={this.onMenuClick}
          />
          {isMenuOpen && (
            <ContextualMenu
              items={[
                { key: "refresh", text: "Refresh", onClick: this.handleRefresh },
                { key: "suche", text: "Suche", onClick: this.handleSearchFromMenu },
                { key: "logout", text: "Logout", onClick: this._logout },
              ]}
              onDismiss={this.onMenuDismiss}
              target="#menuButton"
              alignTargetEdge={true}
            />
          )}
        </header>
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <this.InitialPage />
        </Stack>
      </div>
    );
  }
}

export default Container;
