export default class Config {
  public static baseUrl = "https://www.maklerinfo.biz/";
  public static storageUrl = "https://storage.googleapis.com/cpl-public/cpl/blaudirekt/";
  public static appId = "7ab6052b-d45f-41cc-a5d4-59d4e7e03175";
  public static screenWidth = 800;
  public static screenHeight = 500;

  public static anreden = {
    "1": "Herr",
    "3": "Herr Dr.",
    "4": "Herr Prof.",
    "2": "Frau",
    "5": "Frau Dr.",
    "6": "Frau Prof.",
    "7": "Firma",
  };
  public static kontaktarten = {
    telefon: "Telefon",
    fax: "Fax",
    mobil: "Mobil",
    email: "E-Mail",
    website: "Website",
    brief: "Brief",
    skype: "skype",
  };
  public static zahlweisen = {
    "1": "j\u00e4hrlich",
    "2": "halbj\u00e4hrlich",
    "4": "viertelj\u00e4hrlich",
    "5": "monatlich",
    "6": "einmalig",
  };
  public static sparten = {
    "038": "Ausbildungsversicherung",
    "081": "Baufinanzierung",
    "080": "Bausparen",
    "095": "Bauversicherung",
    "951": "bAV",
    "090": "Berufshaftpflicht",
    "035": "Berufsunf\u00e4higkeit",
    "089": "Betriebshaftpflicht",
    "952": "bKV",
    "059": "Bootsversicherung",
    "200": "Cyberversicherung",
    "135": "Dienstunf\u00e4higkeit",
    "032": "Dread Disease",
    "102": "Elektronik",
    "120": "Ertragsausfall",
    "046": "Geb\u00e4ude (gewerblich)",
    "130": "gebrauchte Lebensversicherung",
    "012": "gesetzliche Krankenkasse",
    "096": "Gew\u00e4sserschadenhaftpflicht",
    "060": "Glasversicherung",
    "033": "Grundf\u00e4higkeiten",
    "097": "Haus- & Grundst\u00fcckshaftpflicht",
    "041": "Hausrat",
    "093": "Hundehaftpflicht",
    "110": "Inhaltsversicherung",
    "800": "Investment-Fonds",
    "088": "Jagdhaftpflicht",
    "031": "Kapitalleben",
    "910": "Kaution",
    "055": "KFZ - Anh\u00e4nger",
    "056": "KFZ - gewerbliche PKW",
    "053": "KFZ - LKW",
    "051": "KFZ - Motorr\u00e4der",
    "050": "KFZ - private PKW",
    "058": "KFZ - Traktoren",
    "052": "KFZ - Wohnmobile",
    "054": "KFZ - Wohnwagenanh\u00e4nger",
    "045": "Kombinationsversicherung",
    "010": "Krankenvollversicherung",
    "013": "Krankenzusatz ",
    "085": "Kredit",
    "036": "Lebensversicherung mit BUZ",
    "210": "MultiRisk (gewerblich)",
    "091": "Pferdehaftpflicht",
    "902": "Pflegerente",
    "112": "Praxistagegeld",
    "092": "Privathaftpflicht",
    "070": "Rechtsschutz",
    "011": "Reisekranken",
    "105": "Reiseversicherung",
    "034": "Rente",
    "131": "Riesterrente",
    "030": "Risikoleben",
    "810": "sonst. ohne. Vst.",
    "999": "Sonstiges",
    "037": "Sterbegeld",
    "100": "Technische Versicherungen",
    "114": "Tierleben /-kranken",
    "190": "Transportversicherung",
    "888": "Unbekannt",
    "020": "Unfall",
    "099": "Verm\u00f6gensschadenhaftpflicht",
    "040": "Wohngeb\u00e4ude",
  };
  public static vertragsstatus = {
    B: "B - Beantragt ohne Policeneingang",
    A: "A - Aktiver Vertrag",
    N: "N - Beitragsfrei",
    H: "H - Aktiver Vertrag Honorarberatung",
    G: "G - Storno nicht abgerechnet",
    S: "S - Storno",
    P: "P - Antrag abgelegt",
    E: "E - Beantragter Fremdvertrag",
    F: "F - Fremdvertrag",
    U: "U - Bestands\u00fcbertragung",
    X: "X - Fremdvertrag ohne Betreuung",
    Y: "Y - Storno Fremdvertrag",
    K: "K - Korrespondenzmaklerschaft",
    V: "V - \u00dcbertragung Komplettbestand",
  };
  public static vertragsarten = ["A", "H", "N", "K", "F", "R", "X", "U", "V"];
  public static antragsarten = ["B", "E"];
  public static gesellschaften = {
    "1081": "Provinzial LV Hannover",
    "1082": "Provinzial Rheinland LV AG",
    "1083": "Provinzial NordWest LV AG",
    "1162": "gelöscht",
    "4135": "Provinzial Krankenversicherung Hannover AG",
    "9012": "Squarelife Insurance AG",
    "10000": "nordinvest",
    "10001": "MAXPOOL Maklerkooperation GmbH",
    "10002": "Konzept & Marketing GmbH",
    "10003": "Prohyp GmbH",
    "10004": "Union Servicegesellschaft",
    "10005": "Netfonds AG",
    "10006": "PMA",
    "10007": "CARGLASS GmbH",
    "10008": "kautionsfrei.de - plusForta GmbH",
    "10009": "NORDVERS GmbH",
    "10010": "Generali Deutschland Versicherung AG",
    "10011": "Schutzklick",
    "10012": "Mplus SELV AG®",
    "10013": "Autark Vertrieb- u. Beteiligungen GmbH",
    "10014": "DKB Deutsche Kreditbank AG",
    "10015": "Creditweb GmbH",
    "10016": "GWS Unternehmensgruppe",
    "10017": "Multi-Invest GmbH",
    "10018": "Killix GmbH",
    "10019": "Hamburger Yacht Versicherung",
    "10020": "Iak GmbH",
    "10021": "Finanz-Zirkel",
    "10022": "direkt-AS GmbH",
    "10023": "ConceptIF GmbH",
    "10024": "IGV - Initiative Gesundversichert e.V.",
    "10025": "KV Werk GmbH",
    "10026": "bisure GmbH",
    "10027": "Policenwerk Assekuradeure GmbH & Co.KG",
    "10028": "Winninger AG",
    "10029": "insuro Maklerservice GmbH",
    "10030": "germanBroker.net AG",
    "10031": "CyberDirekt Cari GmbH",
    "10032": "DV Deutsche Vorsorgedatenbank AG",
    "10033": "FINAS Versicherungsmakler GmbH",
    "10034": "SYNCRO24-assekuradeur GmbH",
    "10035": "SACHPOOL GmbH",
    "10036": "ENRA verzekeringen bv",
    "10037": "Bernhard Assekuranzmakler GmbH & Co. KG",
    "10038": "MMV GmbH",
    "10039": "hendricks GmbH",
    "10040": "Q Versicherungsmakler GmbH",
    "10041": "SVLFG - Sozialversicherung",
    "10042": "Kassenärztliche Bundesvereinigung",
    "10043": "Freedom Insurance Services Ltd.",
    "10044": "Hepster Marke der Moinsure GmbH",
    "10045": "Weddelbrooker Windgilde VVaG",
    "10046": "WIFO GmbH",
    "10047": "ACE Auto Club Europa e.V.",
    "10048": "Verwaltungs-Berufsgenossenschaft (VBG)",
    "10049": "Adam Riese GmbH",
    "10050": "Württembergische Versicherung AG",
    "10051": "Allgemeine Sterbekasse von 1866",
    "10052": "Robert Bosch Risk and Insurance",
    "10053": "Pensionskasse der Mitarbeiter der Hoechst-Gruppe VVaG",
    "10054": "Deutsche Post SNL HR Deutschland",
    "10055": "DEUTSCHER YACHT-POOL Versicherungs-Service GmbH",
    "10056": "Onesty Direct GmbH",
    "10057": "RVN Rechtsanwaltsversorgungswerk",
    "10058": "BAVC-Bruderhilfe e.V.",
    "10059": "Bayerische Versorgungskammer",
    "10060": "Allianz Versicherungs-AG",
    "10061": "WIENER STÄDTISCHE Versicherung AG",
    "10062": "Künstlersozialkasse",
    "10063": "FIRMENICH GmbH & Co. KG",
    "10064": "ODV Versicherungen - Assekuradeur GmbH",
    "10065": "Pantaenius GmbH",
    "10066": "3wefinanzia GmbH - BaufiPlus",
    "10067": "Deutsche Assekuradeur GmbH",
    "10068": "Nordrheinische Ärzteversorgung",
    "10070": "Alte Leipziger",
    "10071": "IKK - Die Innovationskasse",
    "10072": "reiseversicherung.com",
    "10073": "KDZ - Zusatzversorgungskasse Wiesbaden",
    "10074": "Berufsverband der Frauenärzte e.V.",
    "10075": "degenia Versicherungsdienst AG",
    "10076": "BRZ - Bundesverband Reproduktionsmedizinischer Zentren",
    "10077": "Getsafe Digital GmbH",
    "10078": "OCC Assekuradeur GmbH",
    "10079": "Kassenärztliche Vereinigung Hessen",
    "10080": "Standard Life Versicherung",
    "10081": "Baden-Württembergische Versorgungsanstalt",
    "10082": "DUK - Deutsche Unterstützungskasse e.V.",
    "10083": "BKK Freudenberg",
    "10084": "Frankfurter Pensionskasse AG",
    "10085": "Carl Rieck Assecuradeur GmbH",
    "10086": "Dr. E. Wirth & Co. Assekuranzkontor GmbH",
    "10087": "Alteos GmbH",
    "10088": "Intasure - Th. Funk & Sohn GmbH",
    "10089": "Herzenssache. Der Assekuradeur! GmbH",
    "10090": "Kapital Plus",
    "10091": "IKK Südwest",
    "10092": "AOK Baden-Württemberg",
    "10093": "AOK Bayern – Die Gesundheitskasse",
    "10094": "AOK Bremen/Bremerhaven",
    "10095": "AOK – Die Gesundheitskasse in Hessen",
    "10096": "AOK – Die Gesundheitskasse für Niedersachsen",
    "10097": "AOK Nordost – Die Gesundheitskasse",
    "10098": "AOK NordWest - Die Gesundheitskasse",
    "10099": "AOK PLUS – Die Gesundheitskasse für Sachsen und Thüringen",
    "10100": "Nürnberger Versicherungsgruppe",
    "10101": "Nürnberger Beamtenversicherung",
    "10102": "AOK Rheinland/Hamburg – Die Gesundheitskasse",
    "10103": "AOK Rheinland-Pfalz/Saarland – Die Gesundheitskasse",
    "10104": "AOK Sachsen-Anhalt – Die Gesundheitskasse",
    "10105": "CFI Fairpay - Cumerius Management GmbH",
    "10106": "RASTOR Management-Versicherungskonzepte GmbH",
    "10107": "BAHN-BKK",
    "10108": "ACV Automobil-Club Verkehr e.V.",
    "10109": "Betriebskrankenkasse firmus",
    "10110": "Barmenia Versicherungen",
    "10111": "KAB Maklerservice GmbH",
    "10112": "Adcuri GmbH",
    "10113": "Bosch BKK",
    "10114": "BKK ZF & Partner",
    "10115": "BKK EUREGIO",
    "10116": "IKK gesund plus",
    "10117": "BKK Linde",
    "10118": "ZUSATZVERSORGUNGSKASSE der Ev.-luth. Landeskirche Hannovers",
    "10119": "KZVK - Kirchliche Zusatzversorgungskasse Rheinland-Westfalen",
    "10120": "Vorarlberger Landes-Versicherung V.a.G.",
    "10121": "Baobab Insurance GmbH",
    "10122": "NAMMERT Versicherungen",
    "10123": "Vorsorgekasse der Commerzbank Versicherungsverein a.G.",
    "10124": "[pma:] Finanz- und Versicherungsmakler GmbH",
    "10125": "1:1 Assekuranzservice AG",
    "10126": "Apella Aktiengesellschaft",
    "10127": "aruna GmbH",
    "10128": "Fonds Finanz Maklerservice GmbH",
    "10129": "Jung, DMS & Cie. AG",
    "10130": "VEMA",
    "10131": "IBG Immobilienbetreuungs GmbH",
    "10132": "Provinzial Nord Brandkasse AG",
    "10133": "innoAS GmbH",
    "10134": "HEB-Hausratversicherung VVaG",
    "10135": "VGK24 - Krankenkasse vergleichen",
    "10136": "Balunos",
    "10138": "Freeyou Insurance AG",
    "10140": "Atermann König & Pavenstedt GmbH & Co. KG",
    "10141": "Finlex GmbH",
    "10143": "agencio Versicherungsservice AG",
    "10144": "BKK PwC",
    "10145": "KVK Kommunale Versorgungskassen Kurhessen-Waldeck",
    "10146": "Zusatzversorgungskasse des Maler- und Lackiererhandwerks VVaG",
    "10147": "PHÖNIX Schutzgemeinschaft Assekuradeur GmbH",
    "10148": "Automobilclub von Deutschland e.V.",
    "10149": "VdK Versicherung der Kraftfahrt",
    "10150": "Bayern-Versicherung LV AG",
    "10151": "BKK Diakonie",
    "10152": "Krankenversorgung der Bundesbahnbeamten (KVB)",
    "10153": "Meine Baufinanzierung",
    "10154": "Debeka BKK",
    "10155": "Bayerische Ärzteversorgung",
    "10156": "Versorgungswerk der Zahnärztekammer Berlin K.d.ö.R.",
    "10158": "Hessische Zahnärzte-Versorgung",
    "10159": "Versorgungswerk der Zahnärztekammer Mecklenburg-Vorpommern",
    "10161": "Altersversorgungswerk der Zahnärztekammer Niedersachsen",
    "10162": "Versorgungswerk der Zahnärztekammer Nordrhein",
    "10163": "Versorgungsanstalt bei der Landeszahnärztekammer Rheinland-Pfalz",
    "10164": "Versorgungswerk der Ärztekammer des Saarlandes",
    "10165": "Allianz Esa EuroShip GmbH",
    "10166": "Zahnärzteversorgung Sachsen (ZVS)",
    "10167": "Altersversorgungswerk der Zahnärztekammer Sachsen-Anhalt",
    "10168": "Versorgungswerk der Zahnärztekammer Schleswig-Holstein",
    "10169": "Versorgungswerk der Landeszahnärztekammer Thüringen",
    "10170": "Athora / Monument Life Insurance",
    "10171": "Versorgungswerk der Zahnärztekammer Westfalen-Lippe",
    "10172": "BKK-Landesverband NORDWEST KdöR",
    "10190": "sia digital / Qualitypool GmbH",
    "10210": "FiBO Finanzservice GmbH",
    "10220": "DentAssec GmbH",
    "10221": "PharmAssec GmbH",
    "10222": "VitAssec GmbH",
    "10223": "ARCD",
    "10240": "BCA AG",
    "10250": "Liberty Mutual Insurance Europe Limited",
    "10260": "BDAE Holding GmbH",
    "10270": "DEUTSCHER LLOYD GMBH",
    "10280": "Deutscher Ring",
    "10285": "Zeus Service AG",
    "10290": "Investmentgate GmbH & Co. KG",
    "10300": "Familienfürsorge LV AG im Raum der Kirchen",
    "10310": "Amex Pool AG",
    "10330": "HDI Versicherung AG",
    "10370": "Gothaer Versicherungen",
    "10375": "CIF",
    "10380": "FINGRO AG",
    "10390": "Janitos Versicherung AG",
    "10400": "ASKUMA AG",
    "10420": "Hannoversche LV AG",
    "10450": "RWB AG",
    "10510": "VIP - Ansprechpartner",
    "10520": "KZVK Katholische Zusatzversorgungskasse",
    "10521": "EZVK Evangelische Zusatzversorgungskasse",
    "10540": "Landeslebenshilfe V.V.a.G. (LLH)",
    "10550": "HUK-COBURG-LV AG",
    "10560": "ALTE LEIPZIGER Bauspar AG",
    "10600": "Mannheimer Versicherung AG",
    "10601": "Photovoltaikversicherung24",
    "10620": "LV 1871 a.G.",
    "10650": "GE-BE-IN Versicherungen VVaG",
    "10660": "DARAG",
    "10740": "Hans John Versicherungsmakler GmbH",
    "10750": "ÖkoRenta",
    "10810": "ÖVB Öffentliche Versicherung Bremen",
    "10830": "Provinzial Versicherung AG",
    "10840": "Provinzial Rheinland Versicherung AG",
    "10850": "R+V Versicherung AG",
    "10890": "Süddeutsche Krankenversicherung a.G.",
    "10900": "Swiss Life AG",
    "10910": "Sparkassen DirektVersicherung AG",
    "10920": "uniVersa",
    "10970": "INTER Versicherungsgruppe",
    "11030": "WWK LV a.G.",
    "11040": "Stuttgarter Versicherungen",
    "11041": "BY Maklerservice GmbH",
    "11060": "Waldenburger Versicherung AG",
    "11070": "EUROPA Versicherungen",
    "11080": "asspario Versicherungsdienst GmbH",
    "11090": "Deutsche Rentenversicherung",
    "11110": "Energie BKK",
    "11111": "Die Haftpflichtkasse VVaG",
    "11112": "Canada Life Assurance Europe plc",
    "11120": "LVM Versicherungen",
    "11130": "Dialog",
    "11140": "HanseMerkur Versicherungsgruppe",
    "11150": "Credit Life AG",
    "11160": "Zusatzversicherung-Online GmbH",
    "11180": "Signal Iduna",
    "11190": "Horster Brandgilde VVAG",
    "11200": "Covomo Versicherungsvergleich GmbH",
    "11220": "Concordia Versicherungsgruppe",
    "11230": "JURA DIREKT GmbH",
    "11280": "Itzehoer Versicherung / Brandgilde von 1691 VVaG",
    "11285": "AdmiralDirekt.de GmbH",
    "11290": "Fondsdepot Bank GmbH",
    "11300": "FIL Fondsbank GmbH",
    "11320": "Wunderlich Cover Solutions GmbH",
    "11370": "Helvetia Schweizerische Versicherungsgesellschaft AG",
    "11380": "Jöhnke & Reichow Rechtsanwälte",
    "11440": "FRIDAY",
    "11450": "PB LV AG",
    "11490": "ERGO Life S.A.",
    "11510": "ERGO Vorsorge LV AG",
    "11520": "Frankfurter Leben-Gruppe",
    "11570": "Skandia LV AG",
    "11580": "MLP LV",
    "11600": "VPV Versicherungsgruppe",
    "11610": "VBL Versorgungsanstalt",
    "11620": "Ageas Deutschland",
    "11630": "Allgemeine Rentenanstalt",
    "11640": "Neue Leben",
    "11650": "BKK ProVita",
    "11680": "COGITANDA Dataprotect AG",
    "11710": "VOV GmbH",
    "11730": "Baloise / MONEYMAXX",
    "11770": "Concordia oeco LV AG",
    "11790": "Pensionskasse der Caritas VVaG",
    "11810": "HDI LV AG",
    "11820": "Cardif LV",
    "11830": "Mediolanum International Life dac",
    "11850": "FNZ Bank SE",
    "11852": "Union Investment Service Bank AG",
    "12000": "Arisecur",
    "12015": "Bausparkasse Mainz AG",
    "12020": "Schwäbisch Hall",
    "12025": "LBS Landesbausparkasse Süd",
    "12030": "BHW Abwicklung",
    "12035": "Debeka Bausparkasse AG",
    "12040": "Deutsche Bank Bauspar-AG",
    "12045": "Badenia Deutsche Bausparkasse AG",
    "12050": "start:bausparkasse AG",
    "12055": "HUK-Coburg-Bausparkasse AG",
    "12060": "LBS Landesbausparkasse Hessen-Thüringen",
    "12070": "LBS Landesbausparkasse NordOst",
    "12075": "LBS Landesbausparkasse Baden-Württemberg",
    "12090": "LBS Landesbausparkasse Saar",
    "12105": "LBS Landesbausparkasse NordWest",
    "12110": "Quelle Bauspar AG",
    "12115": "SIGNAL IDUNA B",
    "12120": "MDT travel underwriting GmbH",
    "12125": "Wüstenrot Bausparkasse AG",
    "13090": "Protektor LV AG",
    "13380": "Manufaktur Augsburg GmbH",
    "13390": "iptiQ Life S.A.",
    "13430": "Entis LV AG",
    "13440": "Dortmunder LV AG",
    "13467": "Testgesellschaft",
    "15000": "DWS Investment GmbH",
    "15001": "Contrium Emission GmbH",
    "16000": "Fidelity",
    "18038": "Baumhauer & Frenzel GmbH",
    "18342": "Versicherungskammer Maklermanagement",
    "18840": "Generali Deutschland LV AG",
    "20000": "Sielhorster Feuerversicherung",
    "20010": "Hamburger Pensionskasse",
    "20020": "LTA Lifecard-Travel-Assistance GmbH",
    "20030": "IVC",
    "20480": "BVV",
    "21010": "Pensionskasse der EDEKA Organisation V.V.a.G.",
    "21210": "Dresdener Pensionskasse VVaG",
    "21754": "bank zweiplus ag",
    "21890": "SOKA - ZVK des Baugewerbes AG",
    "22222": "Deutsche Ärzteversicherung AG",
    "22540": "Kölner Pensionskasse VVaG",
    "22640": "HDI Pensionskasse AG",
    "22690": "Provinzial Pensionskasse Hannover AG",
    "22890": "Versorgungsausgleichskasse Pensionskasse VVaG",
    "30060": "Rheinisch-Westfälische Sterbekasse",
    "30790": "SOLIDAR",
    "30920": "HDH - Hinterbliebenenkasse der Heilberufe",
    "33130": "Bosch Pensionsfonds AG",
    "33330": "Santander Bank",
    "33331": "Deka Investments",
    "33332": "Sutor Bank",
    "33333": "DAB-Bank",
    "33334": "ING AG",
    "33335": "Augsburger Aktienbank AG",
    "33336": "DSL Bank",
    "40040": "Generali Deutschland Krankenversicherung AG",
    "40041": "Netfonds AG",
    "40060": "Familienfürsorge Krankenversicherung AG",
    "40070": "Versorgungswerk der Architektenkammer Baden-Württemberg",
    "40071": "Versorgungswerk der Landesärztekammer Hessen",
    "40080": "Landesschadenhilfe LSH VaG",
    "40090": "Kommunaler Versorgungsverband Baden-Württemberg",
    "40100": "Alte Oldenburger Krankenversicherung AG",
    "40101": "Panda Tierversicherung",
    "40104": "IKK Brandenburg und Berlin",
    "40110": "Landeskrankenhilfe V.V.a.G.",
    "40170": "NOVA",
    "40200": "Advigon Versicherung AG",
    "40280": "Debeka",
    "40290": "Patria",
    "40370": "Münchener Verein Versicherung",
    "40430": "Hallesche Krankenversicherung a. G.",
    "40440": "DKV Deutsche Krankenversicherung AG",
    "40510": "LIGA Krankenversicherung",
    "40530": "Freie Arzt- und Medizinkasse",
    "40720": "Krankenkasse der Bediensteten",
    "40800": "ZVK Sachsen",
    "40820": "SONO Krankenversicherung a.G.",
    "41010": "DBV Deutsche Beamtenversicherung",
    "41011": "DBV Kompakt",
    "41050": "ERGO Versicherung AG",
    "41080": "Union Krankenversicherung AG",
    "41090": "LVM Krankenversicherungs-AG",
    "41110": "Volksfürsorge - Dialog",
    "41150": "vigo Krankenversicherung VVaG",
    "41155": "grün versichert vigo Krankenversicherung VVaG",
    "41280": "Globality S.A.",
    "41340": "Bayerische Beamtenkrankenkasse BBKK",
    "41350": "Pangaea Life",
    "41380": "die Bayerische",
    "41381": "die Bayerische",
    "41460": "ottonova Krankenversicherung AG",
    "50020": "Mario Strehl - Seminare&Co",
    "50040": "Feuer-Versicherungs-",
    "50041": "Constantia-Versicherungen auf Gegenseitigkeit",
    "50070": "Glasschutzkasse a.G. von 1923",
    "50080": "SHB",
    "50110": "Hamburger Beamten - Feuer- und Einbruchskasse",
    "50120": "Hamburger Lehrer-Feuerkasse",
    "50130": "Lehrer-Feuerversicherungsverein a. G.",
    "50140": "Neuendorfer Brand-Bau-Gilde VVaG",
    "50150": "NV-Versicherungen VVaG",
    "50170": "Ostangler Brandgilde VVaG",
    "50180": "Schutzverein Deutscher Rheder",
    "50200": "AIG Europe S.A.",
    "50290": "Aioi Nissay Dowa Life Insurance of Europe AG",
    "50320": "Hamburger Feuerkasse Versicherungs-AG",
    "50330": "GAV Versicherungs-AG",
    "50350": "AGILA Haustierversicherung AG",
    "50400": "CIC (Deutschland)",
    "50420": "Versicherungskammer Bayern",
    "50430": "Bayerische Landesbrandversicherung AG",
    "50470": "ProTect Versicherung AG",
    "50490": "Cordial Versicherung",
    "50500": "Zurich Gruppe Deutschland",
    "50501": "KAB Zurich",
    "50505": "DA Direkt Versicherung",
    "50530": "Financial Insurance Company Limited",
    "50550": "Verti Versicherung AG",
    "50700": "nexible Versicherung AG",
    "50880": "XL Insurance Company SE",
    "50930": "Westfälische Provinzial Versicherung AG",
    "50960": "HDI Global SE",
    "51260": "Hübener Versicherungs-AG",
    "51290": "DFV Deutsche Familienversicherung AG",
    "51350": "ADAC Autoversicherung AG",
    "51370": "R+V Direktversicherung AG",
    "51590": "ALLCURA Versicherungs-AG",
    "51670": "Markel Insurance SE",
    "51690": "Volkswagen Autoversicherung AG",
    "51720": "BD24 Berlin Direkt Versicherung AG",
    "51740": "ASTRA S.A.",
    "51780": "HDI Global Specialty SE",
    "51840": "astra Versicherung AG",
    "51870": "Great Lakes Insurance SE",
    "51940": "Neue Berliner - Element",
    "52120": "Luko Insurance AG",
    "52210": "mailo Versicherung AG",
    "52220": "andsafe AG",
    "52280": "Newline Europe Versicherung AG",
    "53180": "Baloise Holding AG",
    "53181": "BSG Baloise Service GmbH",
    "53190": "Bayerische Hausbesitzer-Versicherungs-Gesellschaft a. G.",
    "53240": "Bayerischer Versicherungsverband",
    "53260": "Bergische Brandversicherung",
    "53280": "DOCURA VVaG",
    "53281": "A-T-H Silberstedter Versicherungen VVaG",
    "53330": "VRK Versicherer im Raum der Kirchen",
    "53340": "MVK Versicherung VVaG",
    "53390": "Condor Versicherungen",
    "53391": "Pascon GmbH",
    "53400": "Continentale",
    "53410": "EUROPA-go",
    "53420": "Dialog Heilwesen",
    "53480": "Vereinigte Tierversicherung Gesellschaft a.G.",
    "53560": "Europäische Reiseversicherung AG",
    "53570": "Feuer- und Einbruchschadenkasse",
    "53600": "Lippische Landesbrandversicherung AG",
    "53650": "GVO Gegenseitigkeit Versicherung Oldenburg VVaG",
    "53700": "Öffentliche Versicherungen Oldenburg",
    "53710": "Allianz Global Corporate & Specialty SE",
    "53800": "Öffentliche Versicherung Braunschweig",
    "53830": "HAMBURGER PHOENIX",
    "53890": "Gartenbau-Versicherung VVaG",
    "53930": "Isselhorster Versicherung V.a.G.",
    "53940": "GMP - Gesellschaft für Marken- und",
    "53960": "Kölnische Hagel-Versicherungs-Aktiengesellschaft",
    "53990": "KRAVAG",
    "54000": "VGH Versicherungen",
    "54020": "Landwirtschaftlicher",
    "54040": "LBN Versicherungsverein a.G.",
    "54100": "Hiscox S.A.",
    "54120": "Mecklenburgische",
    "54130": "Allianz Agrar AG",
    "54190": "Vereinigte Hagelversicherung VVaG",
    "54270": "Oberhessische",
    "54290": "Delfin Allgemeine Versicherung",
    "54400": "Maklerbetreuung-Gesellschaft",
    "54410": "Allianz Direct Versicherungs-AG",
    "54450": "Schleswiger Versicherungsverein a.G. & Kontor GmbH",
    "54500": "Neodigital Versicherung AG",
    "54550": "ARAG",
    "54560": "Thuringia Versicherungsvermittlungs-GmbH",
    "54590": "Uelzener Allgemeine Versicherungs-Gesellschaft a.G.",
    "54640": "VSW Versicherungsstelle Wiesbaden",
    "54680": "VVDE Versicherungsverband Deutscher Eisenbahnen",
    "54690": "Versicherungsverband für Hebammen",
    "54700": "Fahrlehrerversicherung",
    "54840": "Volkswohl Bund",
    "54841": "prokundo GmbH",
    "54850": "Grundeigentümer Versicherung VVaG",
    "54880": "WERTGARANTIE",
    "54910": "Nordline Assecuranz AG",
    "54970": "REKOGA AG",
    "55040": "HVS Frankfurt a.M.",
    "55050": "GARANTA Versicherungs-AG",
    "55090": "Karlsruher LV AG",
    "55130": "DEVK Versicherungen",
    "55140": "VÖDAG",
    "55149": "AXA - blau",
    "55150": "AXA Versicherung AG",
    "55151": "AXA Kompakt",
    "55152": "CIF",
    "55155": "Assekuranz AG",
    "55157": "CORPORATE INSURANCE",
    "55220": "Dolleruper Freie",
    "55250": "WGV",
    "55290": "ERGO Rechtsschutz",
    "55300": "Vereinigte Schiffs-Versicherung V.a.G",
    "55330": "Aachener Bausparkasse",
    "55390": "Gebäude-Versicherungsgilde",
    "55420": "DIREKTE LEBEN Versicherung AG",
    "55430": "EUROP ASSISTANCE Versicherungs-AG",
    "55440": "Harsewinkeler Versicherungsverein a.G.",
    "55470": "Envivas Krankenvers. AG",
    "55500": "KRAFT Versicherungs-AG",
    "55520": "CosmosDirekt",
    "55560": "Ostbeverner",
    "55570": "HÄGER Versicherungsverein a.G.",
    "55590": "BU-Expertenservice GmbH",
    "55600": "GHV Versicherung",
    "55670": "TRIAS Versicherung AG",
    "55690": "Virado ED Ensure Digital GmbH",
    "55730": "Ideal Versicherung AG",
    "55740": "Monuta",
    "55810": "ADLER Feuerversicherung",
    "55830": "PVAG Polizeiversicherungs-AG",
    "55850": "GVV-Kommunalversicherung VVaG",
    "55890": "GGG Kraftfahrzeug-Reparaturkosten-Versicherungs-AG",
    "55900": "Würzburger Versicherungs AG",
    "55920": "Lloyd's London",
    "55930": "BGV Badische Versicherungen",
    "55980": "VHV Allgemeine Versicherung AG",
    "55990": "Proxalto",
    "56010": "Sterling Insurance Company Ltd.",
    "56090": "Ancora Versicherungs-Vermittlungs GmbH",
    "56110": "FinanceScout 24 AG",
    "56130": "Schwarzwälder Versicherung",
    "56250": "Commercial Union",
    "56320": "Delvag Versicherungs-AG",
    "56340": "Zurich Life Assurance plc",
    "56360": "Allianz Travel",
    "56361": "AGA INTERNATIONAL S.A.",
    "56800": "HVS Hamburger Versicherungs-Service AG",
    "56810": "Carl Rieck Assecuradeur Hamburg GmbH",
    "56900": "SCHWARZMEER UND OSTSEE (SOVAG)",
    "57100": "LPV LV AG",
    "57101": "Heimat Bkk",
    "57200": "Berliner Versicherung AG",
    "57510": "MITSUI MARINE & FIRE INSURANCE",
    "57560": "HAMBURGER HOF",
    "57680": "DEMA Deutsche Versicherungsmakler AG",
    "57710": "Deutsche LV AG",
    "57730": "SAARLAND Versicherungen",
    "57765": "Assekuranza",
    "57800": "InterRisk Versicherungs-AG",
    "57810": "ÖSA Versicherungen",
    "57820": "Sparkassen-Versicherung Sachsen",
    "57860": "OKV",
    "57870": "OVAG - Ostdeutsche Versicherung AG",
    "57890": "Hansa Industrial Insurance",
    "57900": "TARGO LV AG",
    "57920": "Zurich",
    "57970": "Delta Direkt",
    "57980": "RheinLand Versicherung",
    "57981": "rhion digital",
    "58010": "KS/AUXILIA",
    "58020": "DMB Rechtsschutz-Versicherung AG",
    "58030": "DEURAG Deutsche Rechtsschutz-Versicherung AG",
    "58050": "Neue Rechtsschutz-Versicherungsgesellschaft AG",
    "58060": "RECHTSSCHUTZ UNION",
    "58070": "ROLAND Rechtsschutz-Versicherungs-AG",
    "58090": "ADVOCARD Rechtsschutzvers. AG",
    "58130": "ÖRAG Rechtsschutzversicherungs-AG",
    "58180": "HUK-Coburg",
    "58185": "HUK24 AG",
    "58210": "Deutsche Rentenversicherung Knappschaft-Bahn-See",
    "58250": "Allrecht Rechtsschutzversicherungen",
    "58340": "Deutscher Mieterbund e.V.",
    "58560": "PENSIONS-SICHERUNGS-VEREIN",
    "58610": "ALLGEMEINE KREDITVERSICHERUNG",
    "69000": "AM Konzernrückversicherung",
    "69040": "Munich Re",
    "69070": "Deutsche Rückversicherung AG",
    "69080": "E+S Rückversicherung AG",
    "69110": "FRANKONA",
    "69170": "Hamburger Internationale",
    "69180": "Kölnische Rückversicherungs-",
    "69300": "Volkswagen Versicherungsdienst GmbH",
    "69350": "Hanseatica Rückversicherungs-AG",
    "69410": "Hannover Rück SE",
    "69420": "Hannoversche Direktversicherung AG",
    "69640": "Ostfriesische Landschaftliche Brandkasse",
    "70000": "BUpro",
    "70001": "AIA AG",
    "70002": "American Express Services Europe Limited",
    "70003": "AVS-Service Finanz- und Versicherungsmakler GmbH",
    "70004": "AXA Bank AG",
    "70005": "Beazley Insurance dac",
    "70006": "Biometric Underwriting GmbH",
    "70007": "Carl Schröter GmbH & Co. KG",
    "70008": "Deutsche Kautionskasse AG",
    "70009": "Die Sport Assekuranz",
    "70011": "Eberhard, Raith & Partner GmbH",
    "70012": "FLEX Fonds Capital GmbH",
    "70013": "General Accident Fire and Life Assurance Corporation plc",
    "70014": "HKVA - Hanseatische Kasko Versicherungsagentur GmbH",
    "70015": "Inora Life Designated Activity Company",
    "70016": "KOMM Investment & Anlagenvermittlungs GmbH",
    "70017": "MKM Group GmbH",
    "70018": "Neue KV GmbH",
    "70019": "NOVIS Poist'ovna a.s.",
    "70021": "Öffentliche LV Berlin Brandenburg AG",
    "70022": "PCW Assekuranz-Vermittlungs GmbH & Co. KG",
    "70023": "Procheck24 GmbH",
    "70024": "SRC Special Risk Consortium GmbH",
    "70025": "SWICA Krankenversicherung AG",
    "70026": "vs vergleichen-und-sparen GmbH",
    "70027": "TAS Touristik Assekuranzmakler und Service GmbH",
    "70028": "UBS Europe SE",
    "70029": "VERITAS INVESTMENT GmbH",
    "70031": "Weser Assekuranz Kontor GmbH & Co. KG",
    "70032": "Scottish Widows Limited",
    "70033": "Monument Assurance Luxembourg S.A",
    "70038": "ZSH GmbH Finanzdienstleistungen",
    "70060": "National Vulcan",
    "70130": "LV Bestandsservice GmbH",
    "70230": "General Surety & Guarantee Co.",
    "70360": "EUROMAF assurance des ingenieurs",
    "70440": "Cigna Insurance Company",
    "70460": "Chubb European Group SE",
    "70700": "Credit and Guarantee",
    "70770": "EXCESS INSURANCE COMPANY",
    "70830": "International Insurance Corporation",
    "70980": "Nederlanden 1870",
    "71000": "Domcura AG",
    "71001": "Cleo & You GmbH",
    "71030": "English & American",
    "71110": "CIGNA",
    "71120": "Codan Insurance Ltd.",
    "71320": "SOCIETA' CATTOLICA",
    "71340": "Assurant Deutschland GmbH",
    "71370": "Società Italiana",
    "71430": "Abeille Assurances",
    "71760": "Anglo American Insurance",
    "71780": "Assicurazioni Generali S.p.A.",
    "71800": "Fondsnet",
    "71830": "W. R. Berkley Insurance Germany",
    "71900": "O.V.-Ostfriesische Versicherungsbörse GmbH",
    "71910": "Merkur Versicherung",
    "71920": "Reassurantie Maatschappij",
    "71950": "INVESTLIFE LUXEMBOURG S.A.",
    "71960": "ALMALIFE LUXEMBOURG",
    "71990": "LOMBARD INTERNATIONAL",
    "72010": "SAFE ASSURANCE",
    "72030": "ATLANTICLUX société anonyme",
    "72080": "GIP International S.A.",
    "72100": "ALPHA ASSURANCES VIE",
    "72101": "Bertelsmann BKK",
    "72103": "TUI BKK",
    "72104": "BKK Firmus",
    "72110": "ERGO Versicherung AG",
    "72115": "ERGO Direkt AG",
    "72120": "KEMPER S.A.",
    "72130": "Heddington Insurance (U.K.)",
    "72160": "Drei-Banken Versicherungs-AG",
    "72210": "Nemian Life",
    "72300": "AMEV Schadeverzekering N.V.",
    "72350": "Salzburger Landes-Versicherung AG",
    "72360": "LUXSTAR S.A.",
    "72410": "Anglo Underwriting GmbH",
    "72470": "WTCD Insusance Corporation Ltd",
    "72490": "La Mannheim Assicuracioni e",
    "72500": "Sydney Insurance & Reinsurance",
    "72530": "ASSTEL",
    "72570": "Insurance Corporation of",
    "72580": "VAV Versicherungs-AG",
    "72650": "Allianz Life Luxembourg S.A.",
    "72695": "PrismaLife AG",
    "72740": "Kärntner Landesversicherung",
    "72780": "HYPO-VERSICHERUNG AG",
    "72790": "O.O.M. Global",
    "72890": "UNUM",
    "72930": "AG Insurance SA",
    "73030": "FINA",
    "73050": "Care Concept AG",
    "73090": "Donau Versicherung",
    "73180": "Austria LV",
    "73200": "Industrial Insurance",
    "73210": "Otso Loss of Profits",
    "73260": "Traveller's Insurance",
    "73280": "Grazer Wechselseitige Versicherung AG",
    "73300": "BMS International Insurance",
    "73370": "FILO DIRETTO ASSICURAZIONI",
    "73390": "THE SHIPOWNERS' MUTUAL STRIKE",
    "73450": "Royale UAP VIE SA",
    "73460": "SV SparkassenVersicherung Holding AG",
    "73470": "M & G Life Assurance Company",
    "73490": "Hellenobretanniki Insurance",
    "73550": "LA FEDERATION",
    "73620": "C.E.G.I.",
    "73640": "Zeneca Insurance Company",
    "73650": "Sirius International",
    "73660": "ARISA Assurances S.A",
    "73720": "GD Insurance Company Limited",
    "73810": "Feuersozietät Berlin Brandenburg Versicherung AG",
    "73830": "Pohjola Insurance",
    "73870": "Fideuram Vita S.p.A.",
    "73920": "Interlloyd Versicherungs-AG",
    "73940": "Raiffeisen Versicherung AG",
    "73950": "LUXEMBOURG HENIN-Vie",
    "74010": "Oberösterreichische Versicherung AG",
    "74030": "Assuranceforeningen GARD-",
    "74040": "Gjensidige Skadeforsikring",
    "74060": "TIROLER VERSICHERUNG V.a.G.",
    "74070": "uinndirect Insurance Limited",
    "74090": "AVIVA",
    "74100": "FOYER INTERNATIONAL SA",
    "74110": "Amisia Fluss-Kasko",
    "74131": "AO NOW",
    "74210": "ARIG INSURANCE",
    "74220": "BavariaDirekt Versicherung AG",
    "74230": "Akzo Nobel Assurantie N.V.",
    "74240": "Siemens International",
    "74250": "Golden Arches",
    "74300": "Combined Insurance",
    "74400": "Storebrand",
    "74410": "Onderlinge",
    "74530": "Clerical Medical",
    "74540": "CNA Insurance Company",
    "74550": "Probus Insurance Company",
    "74640": "Helvetia Vers.",
    "74690": "myLife LV AG",
    "74780": "Societe Belge d` Assistance",
    "74790": "Vicuritas AG",
    "74800": "Petplan",
    "74810": "FORTUNA LV AG",
    "75000": "Intodo",
    "75010": "Ammerländer Versicherung VVaG",
    "75310": "UNIQA Versicherungen AG",
    "76170": "DB VITA SA",
    "76180": "Coface Deutschland",
    "76185": "Atradius Kreditversicherung",
    "76190": "Euler Hermes Deutschland AG",
    "76430": "Vienna-Life LV AG",
    "77630": "Stonebridge International Insurance Limited",
    "77777": "Manuel Teufel Konzept",
    "78940": "Quantum Leben AG",
    "78970": "Nucleus Life AG",
    "79700": "InShared",
    "80000": "BKK Pfalz",
    "80001": "Die Bergische Krankenkasse",
    "80002": "hkk Handelskrankenkasse",
    "80003": "KKH-Kaufmännische Krankenkasse",
    "80004": "BIG direkt gesund",
    "80005": "Postbeamtenkrankenkasse",
    "80006": "Novitas BKK",
    "80007": "BKK Scheufelen",
    "80008": "BKK exklusiv",
    "80009": "BKK VerbundPlus",
    "80010": "BKK für Heilberufe",
    "80011": "pronova BKK",
    "80012": "R+V Betriebskrankenkasse",
    "80013": "BKK Schwarzwald-Baar-Heuberg",
    "80020": "BKK Herkules",
    "80030": "SBK Siemens-Betriebskrankenkasse",
    "80040": "Hanseatische Krankenkasse HEK",
    "80045": "vivida bkk",
    "80050": "BKK 24",
    "80070": "Audi BKK",
    "80090": "Salus BKK",
    "80101": "PecuPool GmbH",
    "80102": "Seestermüher Kätner-Brandgilde",
    "80103": "Zahnärztekammer Hamburg",
    "80110": "mhplus Betriebskrankenkasse",
    "80140": "Techniker Krankenkasse",
    "80150": "BKK VBU - mkk",
    "80160": "DAK-Gesundheit",
    "80170": "Mobil Krankenkasse",
    "80180": "BARMER",
    "80190": "AOK-Bundesverband",
    "80200": "IKK classic",
    "80210": "SECURVITA Krankenkasse",
    "80220": "VIACTIV Krankenkasse",
    "80800": "ADAC Versicherung AG",
    "81100": "for broker & Ratzke und Ratzke GmbH",
    "81101": "dentolo",
    "81108": "asano AG",
    "81109": "petolo",
    "81200": "INVERS GmbH",
    "81300": "Allstern - Assekuradeur GmbH & Co. KG",
    "82000": "eventAssec - CompactTeam GmbH & Co. KG",
    "83000": "edudip GmbH",
    "86640": "Kasseler Sparkasse",
    "90000": "Talkline GmbH & Co. KG",
    "90010": "CEG",
    "90020": "CSN",
    "90050": "MIDEMA GmbH",
    "90940": "Baloise Vie Luxembourg S.A",
    "91390": "Liechtenstein Life Assurance AG",
    "93340": "DELA LV",
    "94860": "CGPA Europe Underwriting GmbH",
    "94960": "wefox Insurance AG",
    "99910": "vers.diagnose GmbH",
    "99920": "VorsorgeInventur Pro",
    "99999": "blau direkt GmbH",
  };
}
