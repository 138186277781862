/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import * as React from "react";
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence } from "@fluentui/react/lib/Persona";
import { ActionButton, ITooltipHostStyles, Label, TooltipHost } from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';
import { Separator } from "@fluentui/react/lib/Separator";
import { Stack } from "@fluentui/react/lib/Stack";
import { ICustomer, IContactData, IContractData } from "../../interfaces/models";
import { IToken } from "../../interfaces/models";
import Config from "../../Config";
//import Progress from "./Progress";
import DataService from "./DataService";
import { Archive } from "./Archive";

export interface DisplayCustomerProps {
  focusedCustomer: ICustomer;
  deselectCustomer: () => any;
}

export interface DisplayCustomerState {
  showArchive: boolean;
  currentContractId: string;
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export class DisplayCustomer extends React.Component<DisplayCustomerProps, DisplayCustomerState> {
  constructor(props) {
    super(props);
    this.state = {
      showArchive: false,
      currentContractId: null,
    };
  }

  directToProfile = async () => {
    window.open(`${Config.baseUrl}maklerportal/index.php5?kdr=${this.props.focusedCustomer.Id}&show=kunden`);
  };

  showArchive = async (/*contractId?*/) => {
    this.setState({ showArchive: true });
  };

  passContractId = async (currentId?) => {
    this.setState({ currentContractId: currentId });
    this.setState({ showArchive: true });
  };

  goBack = async () => {
    // await refreshToken();
    this.props.deselectCustomer();
  };

  toggleArchivePage = async () => {
    //await refreshToken();
    this.setState({ showArchive: false });
    this.setState({ currentContractId: null });
  };

  public InitialPage: any = () => {
    if (this.state.showArchive) {
      return (
        <div>
          <Archive focusedCustomer={this.props.focusedCustomer} currentId={this.state.currentContractId} />
        </div>
      );
    } else {
      return <this.DisplayCustomer />;
    }
  };

  public DisplayCustomer: any = () => {

    const toProfTooltipId = useId('toProfTooltip');
    const archiveTooltipId = useId('archiveTooltip');

    return (
      <div>
        {/* <Separator /> */}
        <Stack style={{ padding: "14px" }}>
          <this.CreatePersona />
          <Separator />
          <Stack horizontal style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <TooltipHost content="Zum Profil" id={toProfTooltipId} calloutProps={calloutProps} styles={hostStyles}>
            <ActionButton
              style={{ color: "#1E90FF" }}
              onClick={this.directToProfile}
              aria-describedby={toProfTooltipId}
            >
              <img width="23" height="23" src="https://storage.googleapis.com/blaudirekt_gmail_addon/Link.png" />
              {/* ZUM PROFIL */}
            </ActionButton>
            </TooltipHost>
            <TooltipHost content="Archivieren" id={archiveTooltipId} calloutProps={calloutProps} styles={hostStyles}>
            <ActionButton
              style={{ color: "#1E90FF", paddingTop: "3px"}}
              onClick={this.showArchive}
              aria-describedby={archiveTooltipId}
            >
              {/* ARCHIVIEREN */}
              <img
                style={{ paddingLeft: "12px" }}
                width="30"
                height="30"
                src="https://storage.googleapis.com/blaudirekt_gmail_addon/cloud_plus.png"
              />
            </ActionButton>
            </TooltipHost>
          </Stack>
          {/* {title && title.trim() !== "" ? (
            <>
              <Separator />
              <Stack>
                <Label className="ms-fontWeight-regular">Titel</Label>
                <Label className="ms-fontWeight-semibold">{title}</Label>
              </Stack>
            </>
          ) : null} */}
          {/* <Separator />
          <Stack>
            <Label className="ms-fontWeight-regular">Kundennummer</Label>
            <Label className="ms-fontWeight-semibold">{this.props.focusedCustomer.Id}</Label>
          </Stack>/**/}
          <Separator />
          <Label disabled>Anschrift</Label>

          <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              {/* <Stack.Item align="center">
                <img width="30" height="30" src="https://localhost:3000/assets/street.png" />
              </Stack.Item> */}
              <Stack.Item>
                <Stack>
                  <Label disabled style={{ marginBottom: "-8px", fontSize: "11px" }}>STRASSE</Label>
                  <Label>{this.props.focusedCustomer.Hauptwohnsitz.Strasse}</Label>
                </Stack>
              </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 15 }}>
              {/* <Stack.Item align="center">
                <img width="30" height="30" src="https://storage.googleapis.com/cpl-public/cpl/blaudirekt/city.png" />
              </Stack.Item> */}
              <Stack.Item>
                <Stack>
                  <Label disabled style={{ marginBottom: "-8px", fontSize: "11px" }}>ORT</Label>
                  <Label>
                    {this.props.focusedCustomer.Hauptwohnsitz.Postleitzahl +
                      " " +
                      this.props.focusedCustomer.Hauptwohnsitz.Ort}
                  </Label>
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack>

          <Separator />
          <Label disabled>Kontaktdaten</Label>

          {this.buildMultipleContactWidgets}

          <Separator />
          <Label disabled className="ms-fontWeight-regular">
            {"Verträge (" +
              (this.props.focusedCustomer.Contract.length + this.props.focusedCustomer.Applications.length) +
              ")"}
          </Label>
          {this.buildMultipleContractWidgets}
          {this.buildMultipleRequestWidgets}
        </Stack>
      </div>
    );
  };

  public buildMultipleContactWidgets: any[] = this.props.focusedCustomer.Contact.map((current: IContactData) => {
    return (
      <Stack key={current.Id} horizontal tokens={{ childrenGap: 15 }}>
        {/* <Stack.Item align="center">
          <img
            width="30"
            height="30"
            src={"https://storage.googleapis.com/cpl-public/cpl/blaudirekt/" + current.Typ + ".png"}
          />
        </Stack.Item> */}
        <Stack.Item>
          <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
            <Label disabled style={{ marginBottom: "-8px", fontSize: "11px" }}>{current.Kontext === "private" ? "PRIVAT" : "GESCHÄFTLICH"}</Label>
            <Label className="ms-fontWeight-semibold">{current.Value}</Label>
          </Stack>
        </Stack.Item>
      </Stack>
    );
  });

  public buildMultipleContractWidgets: any[] = this.props.focusedCustomer.Contract.map((current: IContractData) => {
    return (
      <Stack key={current.Id} horizontal tokens={{ childrenGap: 15 }}>
        {/* <Stack.Item align="center">
          <img width="30" height="30" src={"https://storage.googleapis.com/cpl-public/cpl/blaudirekt/contracts.png"} />
        </Stack.Item> */}
        <Stack.Item>
          <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
            <Label
              disabled
              style={{
                marginBottom: "-8px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "210px",
                textTransform: "uppercase",
                fontSize: "11px",
              }}
            >
              {Config.sparten[current.Sparte] + ", " + Config.gesellschaften[current.Gesellschaft]}
            </Label>
            <Label className="ms-fontWeight-semibold">{current.Versicherungsscheinnummer}</Label>
          </Stack>
        </Stack.Item>
        <Stack.Item align="center">
          <ActionButton
            style={{ marginLeft: "auto", marginRight: "auto", color: "#1E90FF" }}
            onClick={() => this.passContractId(current.Id)}
          >
            <img width="30" height="30" src="https://outlookaddin.blaudirekt.de/assets/upload.png" />
          </ActionButton>
        </Stack.Item>
      </Stack>
    );
  });

  public buildMultipleRequestWidgets: any[] = this.props.focusedCustomer.Applications.map((current: IContractData) => {
    return (
      <Stack key={current.Id} horizontal tokens={{ childrenGap: 15 }}>
        {/* <Stack.Item align="center">
          <img width="30" height="30" src={"https://storage.googleapis.com/cpl-public/cpl/blaudirekt/contracts.png"} />
        </Stack.Item> */}
        <Stack.Item>
          <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
            <Label
              disabled
              style={{
                marginBottom: "-8px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "210px",
                textTransform: "uppercase",
                fontSize: "11px",
              }}
            >
              {Config.sparten[current.Sparte] + ", " + Config.gesellschaften[current.Gesellschaft]}
            </Label>
            <Label className="ms-fontWeight-semibold">{current.Versicherungsscheinnummer}</Label>
          </Stack>
        </Stack.Item>
        <Stack.Item align="center">
          <ActionButton
            style={{ marginLeft: "auto", marginRight: "auto", color: "#1E90FF" }}
            onClick={() => this.passContractId(current.Id)}
          >
            <img width="30" height="30" src="https://outlookaddin.blaudirekt.de/assets/upload.png" />
          </ActionButton>
        </Stack.Item>
      </Stack>
    );
  });

  public CreatePersona: any = () => {
    const title = this.props.focusedCustomer?.Person?.Titel;
    let anrede = Config.anreden[this.props.focusedCustomer.Anrede];
    let displayName = this.props.focusedCustomer.Person.Vorname + " " + this.props.focusedCustomer.Person.Nachname + (title && title.trim() !== "" ? ", " + title : "");
    if (anrede === Config.anreden["7"]) {
      displayName = this.props.focusedCustomer.Person.Firma;
    }

    let customerPersona: IPersonaSharedProps = {
      imageUrl: Config.storageUrl + (anrede === "Herr" ? "male" : anrede === "Frau" ? "female" : "company") + ".png",
      imageInitials: " ",
      text: anrede,
      secondaryText: displayName,
      showSecondaryText: true,
    };

    return <Persona {...customerPersona} size={PersonaSize.size56} presence={PersonaPresence.none} styles={{secondaryText: {display: 'flex', flexWrap: 'wrap', whiteSpace: 'wrap', height: 'auto'}}}/>;
  };

  render() {
    return (
      <div>
        <this.InitialPage />
      </div>
    );
  }
}

export default DisplayCustomer;
