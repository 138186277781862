import * as React from "react";
import { Stack } from "@fluentui/react";
import Progress from "./Progress";
import Container from "./Container";
import { IToken } from "../../interfaces/models";
import DataService from "./DataService";
import Login from "./Login";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

interface AppState {
  isTokenDataRefreshed: Boolean | null;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isTokenDataRefreshed: null,
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch("/check-token", {
        method: "GET",
        credentials: "include"
      });
  
      if (response.status === 200) {
        const refreshedData = await response.json();
        this.setState({ isTokenDataRefreshed: true });
      } else {
        this.setState({ isTokenDataRefreshed: false });
      }
    } catch (err) {
      this.setState({ isTokenDataRefreshed: false });
    }
  }

  render() {
    const { isOfficeInitialized } = this.props;
    const { isTokenDataRefreshed } = this.state;

    if (isOfficeInitialized && isTokenDataRefreshed === true) {
      return (
        <div className="ms-welcome">
          <Container />
        </div>
      );
    }

    if (isOfficeInitialized && isTokenDataRefreshed === false) {
      return (
        <div className="ms-welcome">
          <Login />
        </div>
      );
    }

    if (!isOfficeInitialized || isTokenDataRefreshed === null) {
      return (
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <Progress message="Bitte warten...." />
        </Stack>
      );
    }
  }
}
