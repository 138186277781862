import * as React from "react";
import { Stack } from "@fluentui/react";
import Progress from "./Progress";
import Container from "./Container";
import { IToken } from "../../interfaces/models";
import DataService from "./DataService";
import Login from "./Login";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

interface AppState {
  isTokenDataRefreshed: Boolean | null;
  useHttpOnlyCookies: Boolean | null;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isTokenDataRefreshed: null,
      useHttpOnlyCookies: null,
    };
  }

  async componentDidMount() {
    const isHttpOnlyCookiesSupported = await this.checkHttpOnlyCookies();
    this.setState({ useHttpOnlyCookies: isHttpOnlyCookiesSupported }, async () => {
      try {
        if (this.state.useHttpOnlyCookies) {
          localStorage.clear();
          const response = await fetch("/check-token", {
            method: "GET",
            credentials: "include"
          });
  
          if (response.status === 200) {
            this.setState({ isTokenDataRefreshed: true });
          } else {
            this.setState({ isTokenDataRefreshed: false });
          }
        } else {
          const accessToken = localStorage.getItem("access_token");
          const refreshToken = localStorage.getItem("refresh_token");
          const accessTokenExpiresAt = localStorage.getItem("access_token_expires_at");
  
          const currentTimestamp = Math.floor(new Date().getTime() / 1000);
          const expireTime = Number(accessTokenExpiresAt);
  
          if ((currentTimestamp >= expireTime) || !accessToken) {
            if (!refreshToken) {
              localStorage.clear();
              this.setState({ isTokenDataRefreshed: false });
            }
  
            try {
              const refreshedData = await DataService.refreshTokendata(refreshToken);

              localStorage.setItem("isLoggedIn", "true");
              localStorage.setItem("access_token", refreshedData.access_token);
              localStorage.setItem("refresh_token", refreshedData.refresh_token);
              // localStorage.setItem("access_token_expires_in", refreshedData.expires_in);
              localStorage.setItem("access_token_expires_at", refreshedData.expires_at);
              this.setState({ isTokenDataRefreshed: true });
            } catch (err) {
              localStorage.clear();
              this.setState({ isTokenDataRefreshed: false });
            }
          } else {
            localStorage.setItem("isLoggedIn", "true");
            this.setState({ isTokenDataRefreshed: true });
          }
        }
      } catch (err) {
        this.setState({ isTokenDataRefreshed: false });
      }
    });
  }

  checkHttpOnlyCookies = async () => {
    try {
        await fetch("/set-test-cookie", {
            credentials: "include",
        });

        const response = await fetch("/check-test-cookie", {
            credentials: "include",
        });

        const data = await response.json();
        return data.httpOnlyCookiesSupported;
    } catch (e) {
        return false;
    }
};

  render() {
    const { isOfficeInitialized } = this.props;
    const { isTokenDataRefreshed, useHttpOnlyCookies } = this.state;

    if (isOfficeInitialized && isTokenDataRefreshed === true) {
      return (
        <div className="ms-welcome">
          <Container />
        </div>
      );
    }

    if (isOfficeInitialized && isTokenDataRefreshed === false) {
      return (
        <div className="ms-welcome">
          <Login useHttpOnlyCookies={useHttpOnlyCookies} />
        </div>
      );
    }

    if (!isOfficeInitialized || isTokenDataRefreshed === null) {
      return (
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <Progress message="Bitte warten...." />
        </Stack>
      );
    }
  }
}
