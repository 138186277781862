/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */
import * as React from "react";
import { Stack, Separator, Label, Text, ButtonType, Link, IImageProps, Image, DefaultButton } from "@fluentui/react";
import Config from "../../Config";
import { IToken } from "../../interfaces/models";
import Progress from "./Progress";
import DataService from "./DataService";

export interface ILoginProps {}

export interface ILoginState {
  isLoaded: boolean;
  isLoggedIn: boolean;
}

const imageProps: Partial<IImageProps> = {
  src: "https://storage.googleapis.com/blaudirekt_gmail_addon/blau.png",
  width: "90%",
  height: 280,
  style: {justifySelf: "center", alignSelf: "center"},
};

export class Login extends React.Component<ILoginProps, ILoginState> {
  private intervalId: NodeJS.Timeout | null = null;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isLoggedIn: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.intervalId = setInterval(this.checkLoginStatus, 2000);

    this.setState({ isLoaded: true });
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkLoginStatus = async () => {
    try {
      const response = await fetch("/is-logged-in", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        if (data.isLoggedIn) {
          this.setState({ isLoggedIn: true });
          this.reloadApp();
        }
      } else {
        console.error("Error while checking login status:", response.status);
      }
    } catch (error) {
      console.error("Error while checking login status:", error);
    }
  };

  reloadApp() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.location.reload();
  }

  InitialPage: any = () => {
    if (!this.state.isLoaded) {
      return <Progress message="Laden..." />;
    }

    return (
      <div>
        <Text style={{ textAlign: "left", fontSize: "18px" }}>Autorisierung erforderlich</Text>
        <Separator />
        <div style={{ padding: "14px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image {...imageProps} alt="Ameise logo" />
        </div>
        <Stack style={{ padding: "14px" }}>
          <Label className="ms-fontWeight-semibold" style={{ fontWeight: "bold" }}>Archiviere Deine Mails</Label>
          <Text>Du kannst E-Mails aus Outlook mit Knopfdruck im MVP archiveren.</Text>
          <Label className="ms-fontWeight-semibold" style={{ fontWeight: "bold" }}>Zeige Deine Kundendaten an</Label>
          <Text>Das Addon zeigt Dir wichtige Informationen zu Deinem Kunden an.</Text>
          <DefaultButton
            style={{ marginTop: "14px", width: "55%", justifySelf: "left", color: "#429ee6" /*"14px"*/}}
            //buttonType={ButtonType.hero}
            onClick={this.login}
            text="Zugriff Gewähren"
          />
          <Separator />
          <Link onClick={this.createAccount} style={{ fontSize: "14px" }}>Noch keinen blau direkt Account? Lerne mehr über unsere Lösungen.</Link>
        </Stack>
      </div>
    );
  };

  createAccount = async () => {
    window.open("https://www.blaudirekt.de/loesungen/");
  };

  login = async () => {
    var left = screen.width / 2 - Config.screenWidth / 2;
    var top = screen.height / 2 - Config.screenHeight / 2;
    window.open(
      window.location.protocol + "//" + window.location.host + `/logmein`,
      "_blank",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        Config.screenWidth +
        ", height=" +
        Config.screenHeight +
        ", top=" +
        top +
        ", left=" +
        left
    );

    const timer = setInterval(() => {
      if (sessionStorage.getItem('isLoggedIn') === 'true') {
        clearInterval(timer);
        window.location.reload();
      }
    }, 500);
  };

  render() {
    return (
      <div>
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <this.InitialPage />
        </Stack>
      </div>
    );
  }
}

export default Login;
