/* eslint-disable prettier/prettier */
import { Checkbox, DefaultButton, Label, Separator, Stack, StackItem, TextField, Toggle } from "@fluentui/react";
import * as React from "react";
import Config from "../../Config";
import { IContractData, ICustomer } from "src/interfaces/models";
import DataService from "./DataService";
import Progress from "./Progress";

export interface ArchiveProps {
  focusedCustomer: ICustomer;
  currentId: string;
}
export interface ArchiveState {
  showArchiveSuccess: null | boolean;
  mailTooBig: null | boolean;
  dataLoading: boolean;
  textFieldValue: null | string;
  toggle: boolean;
  selectedContracts: string[];
}

export class Archive extends React.Component<ArchiveProps, ArchiveState> {
  constructor(props) {
    super(props);
    this.state = {
      showArchiveSuccess: null,
      mailTooBig: null,
      dataLoading: false,
      textFieldValue: "",
      toggle: false,
      selectedContracts: [],
    };

    if (this.props.currentId != null) {
      this.state.selectedContracts.push(this.props.currentId);
    }
  }

  handleTextFieldChange = (event) => {
    const newValue = event.target.value;

    this.setState({ textFieldValue: newValue });
  };

  handleToggleChange = (event, checked) => {
    this.setState({ toggle: checked });
  };

  sendToArchive = async (textFieldValue: string, toggle: boolean, contractId?: string[]) => {
    this.setState({ dataLoading: true });
    let tags;
    if (textFieldValue != null) {
      tags = textFieldValue.split(",");
      tags.push("");
    }
    let result;
    if (contractId && contractId.length > 0) {
      result = await DataService.getMail(this.props.focusedCustomer.Id, toggle, tags, contractId);
    } else {
      result = await DataService.getMail(this.props.focusedCustomer.Id, toggle, tags);
    }
    if (result != null && result != 413) {
      this.setState({ showArchiveSuccess: true, dataLoading: false });
    } else if (result === null) {
      this.setState({ showArchiveSuccess: false, dataLoading: false });
    } else {
      this.setState({ showArchiveSuccess: false, mailTooBig: true, dataLoading: false });
    }
  };

  public MainScreen: any = () => {
    return (
      <div>
        <Stack style={{ padding: "14px" }}>
          <StackItem>
            <Label>Verwaltung der Archiveinträge</Label>
            <Label disabled>Auswahl der Optionen</Label>
          </StackItem>
          <Separator />
          <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign="center">
            <StackItem>
              <Label disabled>KUNDENSICHTBARKEIT</Label>
              <Label>Diesen Archiveintrag für den Kunden sichtbar machen?</Label>
            </StackItem>
            <StackItem align="center">
              <Toggle defaultChecked={this.state.toggle} onChange={this.handleToggleChange} />
            </StackItem>
          </Stack>
          <StackItem>
            <Separator />
            <TextField
              value={this.state.textFieldValue}
              onChange={this.handleTextFieldChange}
              placeholder="Tags durch ein Komma getrennt..."
            />
            <Separator />
          </StackItem>
          <StackItem>
            {this.displayAvailableRequests}
            <Separator />
          </StackItem>
          <StackItem>
            <Label disabled>In Verträge Archivieren</Label>
            {this.displayAvailableContracts}
          </StackItem>
          <StackItem align="start">
            <Separator />
            <DefaultButton
              text="Archivieren"
              onClick={() =>
                this.sendToArchive(this.state.textFieldValue, this.state.toggle, this.state.selectedContracts)
              }
            ></DefaultButton>
          </StackItem>
        </Stack>
      </div>
    );
  };

  public ArchivingPage: any = () => {
    if (this.state.dataLoading) {
      return (
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <Progress message="Laden..." />
        </Stack>
      );
    }

    if (this.state.showArchiveSuccess === true) {
      return (
        <div>
          <Separator />
          <Stack style={{ padding: "14px" }}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <StackItem>
                <img
                  width="30"
                  height="30"
                  src="https://storage.googleapis.com/cpl-public/cpl/blaudirekt/checkCircle.png"
                />
              </StackItem>
              <StackItem>
                <Label>Archivierung fertiggestellt</Label>
              </StackItem>
            </Stack>
            <Separator />
            <Label>Die Email wurde als Dokument unter dem dazugehörigen Kunden hinterlegt.</Label>
          </Stack>
        </div>
      );
    }
    if (this.state.showArchiveSuccess === false && this.state.mailTooBig === null) {
      return (
        <div>
          <Separator />
          <Stack style={{ padding: "14px" }}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <StackItem>
                <img width="30" height="30" src="https://outlookaddin.blaudirekt.de/assets/failedCircle.png" />
              </StackItem>
              <StackItem>
                <Label>Archivierung unvollständig</Label>
              </StackItem>
            </Stack>
            <Separator />
            <Label>Die Email wurde nicht beim Kunden hinterlegt.</Label>
          </Stack>
        </div>
      );
    }
    if (this.state.showArchiveSuccess === false && this.state.mailTooBig === true) {
      return (
        <div>
          <Separator />
          <Stack style={{ padding: "14px" }}>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <StackItem>
                <img width="30" height="30" src="https://outlookaddin.blaudirekt.de/assets/failedCircle.png" />
              </StackItem>
              <StackItem>
                <Label>Archivierung unvollständig</Label>
              </StackItem>
            </Stack>
            <Separator />
            <Label>Die E-Mail ist zu groß zum Archivieren.</Label>
          </Stack>
        </div>
      );
    }
    if (this.state.showArchiveSuccess === null) {
      return <this.MainScreen />;
    }
  };

  public displayAvailableContracts: any[] = this.props.focusedCustomer.Contract.map((current: IContractData) => {
    return (
      <Stack key={current.Id} horizontal tokens={{ childrenGap: 15 }}>
        <StackItem align="center">
          <Checkbox
            defaultChecked={this.props.currentId != null && this.props.currentId === current.Id}
            onChange={(ev, checked) => this.appendContract(checked, current.Id)}
          />
        </StackItem>
        <StackItem>
          <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
            <Label>
              {Config.sparten[current.Sparte] +
                ", " +
                Config.gesellschaften[current.Gesellschaft] +
                ", (" +
                current.Versicherungsscheinnummer +
                ")"}
            </Label>
          </Stack>
        </StackItem>
      </Stack>
    );
  });

  appendContract = async (checked: boolean, contId: string) => {
    if (checked === true) {
      this.state.selectedContracts.push(contId);
    } else {
      let elemToDelete = this.state.selectedContracts.indexOf(contId);
      if (elemToDelete != null) {
        this.state.selectedContracts.splice(elemToDelete, 1);
      }
    }
  };

  public displayAvailableRequests: any[] = this.props.focusedCustomer.Applications.map((current: IContractData) => {
    return (
      <Stack key={current.Id} horizontal tokens={{ childrenGap: 15 }}>
        <StackItem align="center">
          <Checkbox
            defaultChecked={this.props.currentId != null && this.props.currentId === current.Id}
            onChange={(ev, checked) => this.appendContract(checked, current.Id)}
          />
        </StackItem>
        <StackItem>
          <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
            <Label>
              {Config.sparten[current.Sparte] +
                ", " +
                Config.gesellschaften[current.Gesellschaft] +
                ", (" +
                current.Versicherungsscheinnummer +
                ")"}
            </Label>
          </Stack>
        </StackItem>
      </Stack>
    );
  });

  toggleSuccessPage = async () => {
    this.setState({ showArchiveSuccess: false });
  };

  render() {
    return (
      <div>
        <this.ArchivingPage />
      </div>
    );
  }
}
