import App from "./components/App";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import * as React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

/* global Office */

initializeIcons();

let isOfficeInitialized = false;

const title = "Ameise Outlook Addin";

const rootElement: HTMLElement = document.getElementById("container");
const root = createRoot(rootElement);

Office.onReady(() => {
  isOfficeInitialized = true;
  root.render(
    // <StrictMode>
    <App title={title} isOfficeInitialized={isOfficeInitialized} />
    // </StrictMode>
  );
});
