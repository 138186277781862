import axios from "axios";
import { ICustomer, IToken, IContractData, IContactData, IUserInfo } from "../../interfaces/models";
import Config from "../../Config";
/* global Office */

export default class DataService {
  public static async searchCustomersByName(input: string): Promise<ICustomer[]> {
    const path = `getCustomersByName`;

    const method = "post";

    const payload = JSON.stringify({ query: input });
    let result = await this.makeRequest(
      path,
      method,
      this.getAuthorizationHeaders(method, "application/json"),
      payload
    ).catch((err) => {
      console.log("Error:", err);
      return [];
    });
    return result as ICustomer[];
  }

  public static async searchCustomers(): Promise<ICustomer[]> {
    let sender = Office.context.mailbox.item.sender.emailAddress;
    let recipients = [];
    if (sender.toLocaleLowerCase() === Office.context.mailbox.userProfile.emailAddress.toLocaleLowerCase()) {
      recipients = this.getAllRecipients();
    }

    const path = `searchCustomers`;

    const method = "post";

    if (recipients.length != 0) {
      const promiseArray = [];
      for (var person of recipients) {
        const payload = JSON.stringify({ mail: person.emailAddress });
        promiseArray.push(
          this.makeRequest(path, method, this.getAuthorizationHeaders(method, "application/json"), payload)
        );
      }
      let returnValues = await Promise.all(promiseArray).catch((err) => {
        console.log("Error:", err);
        return [];
      });

      let result: ICustomer[] = [];
      for (let value of returnValues) {
        if (value && value.length > 0) {
          result = result.concat(value);
        }
      }
      const ids: any[] = [];
      result = result.filter((item) => {
        if (ids.indexOf(item.Id) === -1) {
          ids.push(item.Id);
          return true;
        }
        return false;
      });
      return result;
    } else {
      const payload = JSON.stringify({ mail: sender });
      let result = await this.makeRequest(
        path,
        method,
        this.getAuthorizationHeaders(method, "application/json"),
        payload
      ).catch((err) => {
        console.log("Error:", err);
        return [];
      });
      return result as ICustomer[];
    }
  }

  public static getAllRecipients() {
    let toRecipients;
    let ccRecipients;
    let bccRecipients;
    let recipientsArray = [];

    if (Office.context.mailbox.item.itemType == Office.MailboxEnums.ItemType.Appointment) {
      toRecipients = Office.context.mailbox.item.requiredAttendees;
      ccRecipients = Office.context.mailbox.item.optionalAttendees;
      if (toRecipients !== "undefined") recipientsArray = recipientsArray.concat(toRecipients);
      if (ccRecipients !== "undefined") recipientsArray = recipientsArray.concat(ccRecipients);
    } else {
      toRecipients = Office.context.mailbox.item.to;
      ccRecipients = Office.context.mailbox.item.cc;
      bccRecipients = Office.context.mailbox.item.bcc;
      if (typeof toRecipients !== "undefined") recipientsArray = recipientsArray.concat(toRecipients);
      if (typeof ccRecipients !== "undefined") recipientsArray = recipientsArray.concat(ccRecipients);
      if (typeof bccRecipients !== "undefined") recipientsArray = recipientsArray.concat(bccRecipients);
    }

    return recipientsArray;
  }

  public static async getMail(customerId: string, isVisible: boolean, tags: string[], contractId?: string[]) {
    const path =
      Config.baseUrl + `service/[mandant]/employee/1.0/rest[mitarbeiter_id]/archiveintraege`;
    let method = "post";

    let payload = await this.fetchMailPayload();
    payload = JSON.stringify({ emailContent: payload });
    let headers = this.getAuthorizationHeaders(method, "application/json", path);
    if (tags && tags.length > 1) {
      headers["X-Dio-Tags"] = JSON.stringify(tags);
    }
    if (isVisible) {
      headers["X-Dio-Metadaten"] = JSON.stringify([{ Value: "_kundensichtbar", Text: "1" }]);
    }
    headers["emailid"] = Office.context.mailbox.item.itemId;
    headers["X-Dio-Typ"] = "email";
    headers["X-Dio-Betreff"] = encodeURIComponent(Office.context.mailbox.item.subject);
    if (contractId && contractId.length > 0) {
      let contractHeader = [];
      contractId.forEach(function (value) {
        contractHeader.push({
          Typ: "vertrag",
          Id: value,
        });
      });
      headers["X-Dio-Zuordnungen"] = JSON.stringify([{ Typ: "kunde", Id: customerId }].concat(contractHeader));
    } else {
      headers["X-Dio-Zuordnungen"] = JSON.stringify([{ Typ: "kunde", Id: customerId }]);
    }

    let result = await this.makeRequest("archiveEmail", "post", headers, payload).catch((err) => {
      if (err.response.status === 413) {
        return err.response.status;
      } else {
        return null;
      }
    });

    return result;
  }
  
  public static getMailBody(): Promise<string> {
    return new Promise((resolve, reject) => {
      Office.context.mailbox.item.getAsFileAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(new Error("There's no email"));
        }
      });
    });
  }

  public static async fetchMailPayload(): Promise<any> {
    try {
      const payload = await this.getMailBody();
      return payload;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  public static async getAdditionalCustomerData(customer: ICustomer): Promise<ICustomer> {
    const contractResponse: IContractData[] = await this.makeRequest(
      `contracts`,
      "get",
      this.getAuthorizationHeaders("get", "application/json", customer.Uri + "/vertraege")
    );

    customer.Contract = contractResponse.filter((el: any) => {
      return Config.vertragsarten.indexOf(el.Status) > -1;
    });

    customer.Applications = contractResponse.filter((el: any) => {
      return Config.antragsarten.indexOf(el.Status) > -1;
    });
    const contactResponse: IContactData[] = await this.makeRequest(
      `contactDetails`,
      "get",
      this.getAuthorizationHeaders("get", "application/json", customer.Uri + "/kontaktdaten")
    );
    customer.Contact = contactResponse;

    return customer;
  }

  public static getAuthorizationHeaders(method: string, contentType: string, uriToCall = null) {
    let obj = {
      "Access-Control-Allow-Origin": "*",
      "X-Dio-Method": method.toUpperCase(),
      "Content-Type": contentType,
    };
    uriToCall ? (obj["uritocall"] = uriToCall) : null;
    return obj;
  }

  public static async makeRequest(
    path: string,
    method: "get" | "delete" | "patch" | "post" | "put",
    headers: any,
    payload?: any
  ): Promise<any> {
    let result: any = await axios({
      method: method,
      url: `${window.location.protocol}//${window.location.host}/${path}`,
      headers: headers,
      data: payload,
    });
    return result.data;
  }
}
