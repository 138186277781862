import * as React from "react";
import { Spinner, SpinnerType } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";

export interface ProgressProps {
  message: string;
}

export default class Progress extends React.Component<ProgressProps> {
  render() {
    const { message } = this.props;

    return (
      <Stack tokens={{ childrenGap: 20 }} style={{ padding: "14px" }}>
        <Spinner type={SpinnerType.large} label={message} />
      </Stack>
    );
  }
}
