/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */
import * as React from "react";
import { Stack, Separator, Label, Text, ButtonType, Link, IImageProps, Image, DefaultButton } from "@fluentui/react";
import Config from "../../Config";
import { IToken } from "../../interfaces/models";
import Progress from "./Progress";
import DataService from "./DataService";

export interface ILoginProps {
  useHttpOnlyCookies: Boolean;
}

export interface ILoginState {
  isLoaded: boolean;
  isLoggedIn: boolean;
}

const imageProps: Partial<IImageProps> = {
  src: "https://storage.googleapis.com/blaudirekt_gmail_addon/blau.png",
  width: "90%",
  height: 280,
  style: {justifySelf: "center", alignSelf: "center"},
};

export class Login extends React.Component<ILoginProps, ILoginState> {
  private intervalId: NodeJS.Timeout | null = null;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isLoggedIn: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.intervalId = setInterval(this.checkLoginStatus, 2000);

    this.setState({ isLoaded: true });
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkLoginStatus = async () => {
    const { useHttpOnlyCookies } = this.props;

    try {
      if (useHttpOnlyCookies) {
        const response = await fetch("/is-logged-in", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          if (data.isLoggedIn) {
            this.setState({ isLoggedIn: true });
            this.reloadApp();
          }
        } else {
          console.error("Error while checking login status:", response.status);
        }
      } else {
        const isLoggedIn = localStorage.getItem("isLoggedIn");

        if (isLoggedIn === "true") {
          this.setState({ isLoggedIn: true });
          this.reloadApp();
        }   
      }
    } catch (error) {
      console.error("Error while checking login status:", error);
    }
  };

  reloadApp() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.location.reload();
  }

  InitialPage: any = () => {
    if (!this.state.isLoaded) {
      return <Progress message="Laden..." />;
    }

    return (
      <div>
        <Text style={{ textAlign: "left", fontSize: "18px" }}>Autorisierung erforderlich</Text>
        <Separator />
        <div style={{ padding: "14px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image {...imageProps} alt="Ameise logo" />
        </div>
        <Stack style={{ padding: "14px" }}>
          <Label className="ms-fontWeight-semibold" style={{ fontWeight: "bold" }}>Archiviere Deine Mails</Label>
          <Text>Du kannst E-Mails aus Outlook mit Knopfdruck im MVP archiveren.</Text>
          <Label className="ms-fontWeight-semibold" style={{ fontWeight: "bold" }}>Zeige Deine Kundendaten an</Label>
          <Text>Das Addon zeigt Dir wichtige Informationen zu Deinem Kunden an.</Text>
          <DefaultButton
            style={{ marginTop: "14px", width: "55%", justifySelf: "left", color: "#429ee6" /*"14px"*/}}
            //buttonType={ButtonType.hero}
            onClick={this.login}
            text="Zugriff Gewähren"
          />
          <Separator />
          <Link onClick={this.createAccount} style={{ fontSize: "14px" }}>Noch keinen blau direkt Account? Lerne mehr über unsere Lösungen.</Link>
        </Stack>
      </div>
    );
  };

  createAccount = async () => {
    window.open("https://www.blaudirekt.de/loesungen/");
  };

  login = async () => {
    const { useHttpOnlyCookies } = this.props;
    const logmeinUrl = `${window.location.protocol}//${window.location.host}/logmein`;
    const loginPopupUrl = `${window.location.protocol}//${window.location.host}/login-popup`;

    if (useHttpOnlyCookies) {
      var left = screen.width / 2 - Config.screenWidth / 2;
      var top = screen.height / 2 - Config.screenHeight / 2;
      window.open(
        logmeinUrl,
        "_blank",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          Config.screenWidth +
          ", height=" +
          Config.screenHeight +
          ", top=" +
          top +
          ", left=" +
          left
      );
    } else {
      Office.context.ui.displayDialogAsync(
        loginPopupUrl,
        { width: (Config.screenWidth / screen.width) * 100, height: (Config.screenHeight / screen.height) * 100,
          promptBeforeOpen: false, displayInIframe: false
        },
        (result) => {
          // Open a separate window
          const options = `popup,width=400,height=600,left=${window.screenLeft},top=${window.screenTop},noopener,noreferrer`;
          setTimeout(() => {
            window.open(logmeinUrl, "DoubleJumpLogin", options);
          }, 1000);

          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const dialog = result.value;

            dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
              if ("message" in args) {
                try {
                  const data = JSON.parse(args.message);

                  localStorage.setItem('isLoggedIn', data.isLoggedIn);
                  localStorage.setItem('access_token', data.access_token);
                  localStorage.setItem('refresh_token', data.refresh_token);
                  localStorage.setItem('mandant', data.mandant);
                  localStorage.setItem('mitarbeiter_id', data.mitarbeiter_id);
                  localStorage.setItem('access_token_expires_in', data.access_token_expires_in);
                  localStorage.setItem('access_token_expires_at', data.access_token_expires_at);

                  await fetch('/logout', { method: 'POST' });

                  dialog.close();
                } catch (error) {
                  console.error("Error while checking login status:", error);
                }
              }
            });
          } else {
            console.error("Failed to display dialog: ", result.error);
          }
        }
      );
    }
  };

  render() {
    return (
      <div>
        <Stack tokens={{ childrenGap: 15 }} style={{ padding: "14px" }}>
          <this.InitialPage />
        </Stack>
      </div>
    );
  }
}

export default Login;
